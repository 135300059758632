.logo_slider_feat {
  opacity: 0;
  visibility: hidden;
  transition: .3s ease-in-out; }
  .logo_slider_feat.visible {
    opacity: 1;
    visibility: visible;
    transition: .3s ease-in-out; }
  .logo_slider_feat__box {
    margin: 30px 50px;
    outline: none;
    text-decoration: none; }
  .logo_slider_feat__img {
    display: block;
    height: 80px;
    width: 110px;
    object-fit: contain; }
  .logo_slider_feat__container {
    height: 150px;
    overflow: hidden; }
